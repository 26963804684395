<template>
    <section v-if="initiationId">
        <div id="boost-job-vacancy-v2" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="closeModal"/>
                <div class="uk-modal-header uk-flex-inline uk-width-1-1">
                    <img
                        src="/images/job_applicant/rocket-white.svg"
                        alt="Boost"
                        style="border-radius: 100%; background: #FEBE10; padding: 1px; max-width: 40px; height: 40px; aspect-ratio: 1/1;"
                    />
                    <h2 class="uk-modal-title header-font">Boost this Vacancy</h2>
                </div>
                <section class="uk-modal-body text-black" style="padding: 20px;">
                    <p>
                        Are you sure you want to boost this vacancy?<br/>
                        If you proceed, the vacancy updated date will be set to today,<br/>
                        <span style="font-weight: 700;">{{ boostedAt }}</span> and the vacancy will get boosted on App.
                    </p>
                </section>
                <div class="uk-modal-footer justify-between">
                    <button
                        class="uk-button"
                        style="border: 1px solid #EF5350; color: #EF5350; background: #FFF;"
                        @click="closeModal"
                    >
                        Cancel
                    </button>

                    <button
                        class="uk-button text-white"
                        :class="[submitLoading && 'btn-disabled' || 'uk-button-primary']"
                        @click="submitBoost"
                    >
                        <template v-if="submitLoading">
                            <span uk-spinner="ratio: 0.5"></span>
                            <span class="uk-margin-small-left">Loading</span>
                        </template>
                        <template v-else>
                            Proceed
                        </template>
                    </button>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import {mapActions} from 'vuex';

export default {
    name: "ModalBoostJobVacancyV2",
    props: {
        initiationId: {
            type: String,
            default: null,
        },
        boostedAt: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            submitLoading: false,
        };
    },
    methods: {
        ...mapActions({
            updateStatus: 'initiation/updateStatusV4'
        }),
        async submitBoost() {
            try {
                this.submitLoading = true;

                await this.updateStatus({
                    id: this.initiationId,
                    job_boosted: true,
                });

                this.$emit("success");
            } catch (e) {
                console.error(e);
            } finally {
                setTimeout(() => this.submitLoading = false, 750);
                this.closeModal(750);
            }
        },
        closeModal(timeout) {
            const params = [];
            if (timeout > 0) params.push(timeout);

            window.UIkit.modal("#boost-job-vacancy-v2").$destroy(true);
            this.$emit("cancel", ...params);
        },
    },
};
</script>
<style scoped>
.btn-disabled {
    background-color: #979797;
    pointer-events: none;
}

.text-white {
    color: #fff !important;
}

.text-black {
    color: #000 !important;
}

.justify-between {
    display: flex;
    justify-content: space-between;
}

.header-font {
    font-size: 24px;
    line-height: 1.5;
    margin: 0 16px;
    letter-spacing: -0.2px;
}
</style>
